var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cards" },
    [
      _c(
        "div",
        { staticClass: "cards__list" },
        [
          _vm.showCard && _vm.showAddCard
            ? _c("div", { staticClass: "card" }, [
                _c("div", {}, [
                  _c(
                    "div",
                    { staticClass: "card__figure" },
                    [
                      _c("v-textbox", {
                        staticClass: "card",
                        staticStyle: { color: "#fff" },
                        attrs: {
                          label: "Insira o número do cartão físico",
                          name: "cardNumber",
                          placeholder: "•••• •••• •••• ••••",
                          required: "",
                          mask: "#### #### #### ####",
                          type: "text",
                        },
                        model: {
                          value: _vm.cardNumber,
                          callback: function ($$v) {
                            _vm.cardNumber = $$v
                          },
                          expression: "cardNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "card__actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "card__actions__action",
                        class: {
                          disable:
                            _vm.cardNumber == null ||
                            _vm.cardNumber.length !== 19,
                        },
                        on: { click: _vm.addCard },
                      },
                      [
                        !_vm.loadingSpinner
                          ? _c("v-icon", {
                              staticClass: "card__actions__action__icon",
                              attrs: { name: "add" },
                            })
                          : _vm._e(),
                        !_vm.loadingSpinner
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "card__actions__action__text addBtn__actions__action__loading",
                              },
                              [_vm._v("Adicionar")]
                            )
                          : _vm._e(),
                        _vm.loadingSpinner
                          ? _c("v-icon", {
                              staticClass:
                                "refresh-icon__figure__toggle rotating-refresh-icon",
                              staticStyle: { width: "19px", height: "19px" },
                              attrs: { name: "refresh-icon" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.showDigitalCard && _vm.showAddCard
            ? _c("div", { staticClass: "card" }, [
                _c("div", {}, [
                  _c(
                    "div",
                    { staticClass: "card__figure" },
                    [
                      _c("v-textbox", {
                        staticClass: "card",
                        staticStyle: { color: "#fff" },
                        attrs: {
                          label: "Código do cartão digital",
                          name: "codeSended",
                          placeholder: "••••-••••-••••",
                          required: "",
                          mask: "XXXX-XXXX-XXXX",
                          type: "text",
                        },
                        model: {
                          value: _vm.codeSended,
                          callback: function ($$v) {
                            _vm.codeSended = $$v
                          },
                          expression: "codeSended",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "card__actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "card__actions__action",
                        class: {
                          disable:
                            _vm.codeSended == null ||
                            _vm.codeSended.length !== 14,
                        },
                        on: { click: _vm.unlockDigitalCard },
                      },
                      [
                        !_vm.loadingSpinner
                          ? _c("v-icon", {
                              staticClass: "card__actions__action__icon",
                              attrs: { name: "add" },
                            })
                          : _vm._e(),
                        !_vm.loadingSpinner
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "card__actions__action__text addBtn__actions__action__loading",
                              },
                              [_vm._v("Adicionar")]
                            )
                          : _vm._e(),
                        _vm.loadingSpinner
                          ? _c("v-icon", {
                              staticClass:
                                "refresh-icon__figure__toggle rotating-refresh-icon",
                              staticStyle: { width: "19px", height: "19px" },
                              attrs: { name: "refresh-icon" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._l(_vm.cards, function (card, index) {
            return _c(
              "v-card",
              _vm._g(
                _vm._b(
                  {
                    key: index,
                    attrs: {
                      showCanceledCard: _vm.showCanceledCard,
                      card: card,
                    },
                  },
                  "v-card",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            )
          }),
        ],
        2
      ),
      _c("v-block-card"),
      _c("v-request-new-card"),
      _vm.showPass
        ? _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("CardPassForm", {
                attrs: { callGetCardsFromAPI: _vm.getCardsFromAPI },
                on: {
                  close: function ($event) {
                    _vm.showPass = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }